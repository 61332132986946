export default function onScroll() {
  const swiper = this;
  const { wrapperEl, rtlTranslate, enabled } = swiper;
  if (!enabled) return;
  previousTranslate = translate;
  if (isHorizontal()) {
    translate = -wrapperEl.scrollLeft;
  } else {
    translate = -wrapperEl.scrollTop;
  }
  // eslint-disable-next-line
  if (translate === -0) translate = 0;

  updateActiveIndex();
  updateSlidesClasses();

  let newProgress;
  const translatesDiff = maxTranslate() - minTranslate();
  if (translatesDiff === 0) {
    newProgress = 0;
  } else {
    newProgress = (translate - minTranslate()) / translatesDiff;
  }
  if (newProgress !== progress) {
    updateProgress(rtlTranslate ? -translate : translate);
  }

  emit('setTranslate', translate, false);
}
