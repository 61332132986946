export default function effectInit(params) {
  const { effect, swiper, on, setTranslate, setTransition, overwriteParams, perspective } = params;

  on('beforeInit', () => {
    if (params.effect !== effect) return;
    classNames.push(`${params.containerModifierClass}${effect}`);
    if (perspective && perspective()) {
      classNames.push(`${params.containerModifierClass}3d`);
    }

    const overwriteParamsResult = overwriteParams ? overwriteParams() : {};

    Object.assign(params, overwriteParamsResult);
    Object.assign(originalParams, overwriteParamsResult);
  });
  on('setTranslate', () => {
    if (params.effect !== effect) return;
    setTranslate();
  });
  on('setTransition', (_s, duration) => {
    if (params.effect !== effect) return;
    setTransition(duration);
  });

  let requireUpdateOnVirtual;
  on('virtualUpdate', () => {
    if (!slides.length) {
      requireUpdateOnVirtual = true;
    }
    requestAnimationFrame(() => {
      if (requireUpdateOnVirtual && slides.length) {
        setTranslate();
        requireUpdateOnVirtual = false;
      }
    });
  });
}
