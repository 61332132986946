

import { getDocument } from 'ssr-window';
import { nextTick } from '../../shared/utils.js';

export default function Autoplay({ swiper, extendParams, on, emit }) {
  let timeout;

  autoplay = {
    running: false,
    paused: false,
  };

  extendParams({
    autoplay: {
      enabled: false,
      delay: 3000,
      waitForTransition: true,
      disableOnInteraction: true,
      stopOnLastSlide: false,
      reverseDirection: false,
      pauseOnMouseEnter: false,
    },
  });

  function run() {
    const $activeSlideEl = slides.eq(activeIndex);
    let delay = params.autoplay.delay;
    if ($activeSlideEl.attr('data-swiper-autoplay')) {
      delay = $activeSlideEl.attr('data-swiper-autoplay') || params.autoplay.delay;
    }
    clearTimeout(timeout);
    timeout = nextTick(() => {
      let autoplayResult;
      if (params.autoplay.reverseDirection) {
        if (params.loop) {
          loopFix();
          autoplayResult = slidePrev(params.speed, true, true);
          emit('autoplay');
        } else if (!isBeginning) {
          autoplayResult = slidePrev(params.speed, true, true);
          emit('autoplay');
        } else if (!params.autoplay.stopOnLastSlide) {
          autoplayResult = slideTo(
            slides.length - 1,
            params.speed,
            true,
            true,
          );
          emit('autoplay');
        } else {
          stop();
        }
      } else if (params.loop) {
        loopFix();
        autoplayResult = slideNext(params.speed, true, true);
        emit('autoplay');
      } else if (!isEnd) {
        autoplayResult = slideNext(params.speed, true, true);
        emit('autoplay');
      } else if (!params.autoplay.stopOnLastSlide) {
        autoplayResult = slideTo(0, params.speed, true, true);
        emit('autoplay');
      } else {
        stop();
      }
      if (params.cssMode && autoplay.running) run();
      else if (autoplayResult === false) {
        run();
      }
    }, delay);
  }
  function start() {
    if (typeof timeout !== 'undefined') return false;
    if (autoplay.running) return false;
    autoplay.running = true;
    emit('autoplayStart');
    run();
    return true;
  }
  function stop() {
    if (!autoplay.running) return false;
    if (typeof timeout === 'undefined') return false;

    if (timeout) {
      clearTimeout(timeout);
      timeout = undefined;
    }
    autoplay.running = false;
    emit('autoplayStop');
    return true;
  }
  function pause(speed) {
    if (!autoplay.running) return;
    if (autoplay.paused) return;
    if (timeout) clearTimeout(timeout);
    autoplay.paused = true;
    if (speed === 0 || !params.autoplay.waitForTransition) {
      autoplay.paused = false;
      run();
    } else {
      ['transitionend', 'webkitTransitionEnd'].forEach((event) => {
        $wrapperEl[0].addEventListener(event, onTransitionEnd);
      });
    }
  }
  function onVisibilityChange() {
    const document = getDocument();
    if (document.visibilityState === 'hidden' && autoplay.running) {
      pause();
    }
    if (document.visibilityState === 'visible' && autoplay.paused) {
      run();
      autoplay.paused = false;
    }
  }
  function onTransitionEnd(e) {
    if (!swiper || destroyed || !$wrapperEl) return;
    if (e.target !== $wrapperEl[0]) return;
    ['transitionend', 'webkitTransitionEnd'].forEach((event) => {
      $wrapperEl[0].removeEventListener(event, onTransitionEnd);
    });
    autoplay.paused = false;
    if (!autoplay.running) {
      stop();
    } else {
      run();
    }
  }
  function onMouseEnter() {
    if (params.autoplay.disableOnInteraction) {
      stop();
    } else {
      emit('autoplayPause');
      pause();
    }

    ['transitionend', 'webkitTransitionEnd'].forEach((event) => {
      $wrapperEl[0].removeEventListener(event, onTransitionEnd);
    });
  }
  function onMouseLeave() {
    if (params.autoplay.disableOnInteraction) {
      return;
    }
    autoplay.paused = false;
    emit('autoplayResume');
    run();
  }
  function attachMouseEvents() {
    if (params.autoplay.pauseOnMouseEnter) {
      $el.on('mouseenter', onMouseEnter);
      $el.on('mouseleave', onMouseLeave);
    }
  }
  function detachMouseEvents() {
    $el.off('mouseenter', onMouseEnter);
    $el.off('mouseleave', onMouseLeave);
  }

  on('init', () => {
    if (params.autoplay.enabled) {
      start();
      const document = getDocument();
      document.addEventListener('visibilitychange', onVisibilityChange);
      attachMouseEvents();
    }
  });
  on('beforeTransitionStart', (_s, speed, internal) => {
    if (autoplay.running) {
      if (internal || !params.autoplay.disableOnInteraction) {
        autoplay.pause(speed);
      } else {
        stop();
      }
    }
  });
  on('sliderFirstMove', () => {
    if (autoplay.running) {
      if (params.autoplay.disableOnInteraction) {
        stop();
      } else {
        pause();
      }
    }
  });
  on('touchEnd', () => {
    if (
      params.cssMode &&
      autoplay.paused &&
      !params.autoplay.disableOnInteraction
    ) {
      run();
    }
  });
  on('destroy', () => {
    detachMouseEvents();
    if (autoplay.running) {
      stop();
    }
    const document = getDocument();
    document.removeEventListener('visibilitychange', onVisibilityChange);
  });

  Object.assign(autoplay, {
    pause,
    run,
    start,
    stop,
  });
}
