import classesToSelector from '../../shared/classes-to-selector.js';
import $ from '../../shared/dom.js';

export default function A11y({ swiper, extendParams, on }) {
  extendParams({
    a11y: {
      enabled: true,
      notificationClass: 'swiper-notification',
      prevSlideMessage: 'Previous slide',
      nextSlideMessage: 'Next slide',
      firstSlideMessage: 'This is the first slide',
      lastSlideMessage: 'This is the last slide',
      paginationBulletMessage: 'Go to slide {{index}}',
      slideLabelMessage: '{{index}} / {{slidesLength}}',
      containerMessage: null,
      containerRoleDescriptionMessage: null,
      itemRoleDescriptionMessage: null,
      slideRole: 'group',
    },
  });

  let liveRegion = null;

  function notify(message) {
    const notification = liveRegion;
    if (notification.length === 0) return;
    notification.html('');
    notification.html(message);
  }

  function getRandomNumber(size = 16) {
    const randomChar = () => Math.round(16 * Math.random()).toString(16);
    return 'x'.repeat(size).replace(/x/g, randomChar);
  }
  function makeElFocusable($el) {
    $el.attr('tabIndex', '0');
  }
  function makeElNotFocusable($el) {
    $el.attr('tabIndex', '-1');
  }
  function addElRole($el, role) {
    $el.attr('role', role);
  }
  function addElRoleDescription($el, description) {
    $el.attr('aria-roledescription', description);
  }
  function addElControls($el, controls) {
    $el.attr('aria-controls', controls);
  }
  function addElLabel($el, label) {
    $el.attr('aria-label', label);
  }
  function addElId($el, id) {
    $el.attr('id', id);
  }
  function addElLive($el, live) {
    $el.attr('aria-live', live);
  }
  function disableEl($el) {
    $el.attr('aria-disabled', true);
  }
  function enableEl($el) {
    $el.attr('aria-disabled', false);
  }

  function onEnterOrSpaceKey(e) {
    if (e.keyCode !== 13 && e.keyCode !== 32) return;
    const params = params.a11y;
    const $targetEl = $(e.target);
    if (navigation && navigation.$nextEl && $targetEl.is(navigation.$nextEl)) {
      if (!(isEnd && !params.loop)) {
        slideNext();
      }
      if (isEnd) {
        notify(params.lastSlideMessage);
      } else {
        notify(params.nextSlideMessage);
      }
    }
    if (navigation && navigation.$prevEl && $targetEl.is(navigation.$prevEl)) {
      if (!(isBeginning && !params.loop)) {
        slidePrev();
      }
      if (isBeginning) {
        notify(params.firstSlideMessage);
      } else {
        notify(params.prevSlideMessage);
      }
    }

    if (
      pagination &&
      $targetEl.is(classesToSelector(params.pagination.bulletClass))
    ) {
      $targetEl[0].click();
    }
  }

  function updateNavigation() {
    if (params.loop || params.rewind || !navigation) return;
    const { $nextEl, $prevEl } = navigation;

    if ($prevEl && $prevEl.length > 0) {
      if (isBeginning) {
        disableEl($prevEl);
        makeElNotFocusable($prevEl);
      } else {
        enableEl($prevEl);
        makeElFocusable($prevEl);
      }
    }
    if ($nextEl && $nextEl.length > 0) {
      if (isEnd) {
        disableEl($nextEl);
        makeElNotFocusable($nextEl);
      } else {
        enableEl($nextEl);
        makeElFocusable($nextEl);
      }
    }
  }

  function hasPagination() {
    return pagination && pagination.bullets && pagination.bullets.length;
  }

  function hasClickablePagination() {
    return hasPagination() && params.pagination.clickable;
  }

  function updatePagination() {
    const params = params.a11y;
    if (!hasPagination()) return;
    pagination.bullets.each((bulletEl) => {
      const $bulletEl = $(bulletEl);
      if (params.pagination.clickable) {
        makeElFocusable($bulletEl);
        if (!params.pagination.renderBullet) {
          addElRole($bulletEl, 'button');
          addElLabel(
            $bulletEl,
            params.paginationBulletMessage.replace(/\{\{index\}\}/, $bulletEl.index() + 1),
          );
        }
      }
      if ($bulletEl.is(`.${params.pagination.bulletActiveClass}`)) {
        $bulletEl.attr('aria-current', 'true');
      } else {
        $bulletEl.removeAttr('aria-current');
      }
    });
  }

  const initNavEl = ($el, wrapperId, message) => {
    makeElFocusable($el);
    if ($el[0].tagName !== 'BUTTON') {
      addElRole($el, 'button');
      $el.on('keydown', onEnterOrSpaceKey);
    }
    addElLabel($el, message);
    addElControls($el, wrapperId);
  };

  const handleFocus = (e) => {
    const slideEl = e.target.closest(`.${params.slideClass}`);
    if (!slideEl || !slides.includes(slideEl)) return;
    const isActive = slides.indexOf(slideEl) === activeIndex;
    const isVisible =
      params.watchSlidesProgress &&
      visibleSlides &&
      visibleSlides.includes(slideEl);
    if (isActive || isVisible) return;
    slideTo(slides.indexOf(slideEl), 0);
  };

  function init() {
    const params = params.a11y;

    $el.append(liveRegion);

    // Container
    const $containerEl = $el;
    if (params.containerRoleDescriptionMessage) {
      addElRoleDescription($containerEl, params.containerRoleDescriptionMessage);
    }
    if (params.containerMessage) {
      addElLabel($containerEl, params.containerMessage);
    }

    // Wrapper
    const $wrapperEl = $wrapperEl;
    const wrapperId = $wrapperEl.attr('id') || `swiper-wrapper-${getRandomNumber(16)}`;
    const live = params.autoplay && params.autoplay.enabled ? 'off' : 'polite';
    addElId($wrapperEl, wrapperId);
    addElLive($wrapperEl, live);

    // Slide
    if (params.itemRoleDescriptionMessage) {
      addElRoleDescription($(slides), params.itemRoleDescriptionMessage);
    }
    addElRole($(slides), params.slideRole);

    const slidesLength = params.loop
      ? slides.filter((el) => !el.classList.contains(params.slideDuplicateClass))
          .length
      : slides.length;
    slides.each((slideEl, index) => {
      const $slideEl = $(slideEl);
      const slideIndex = params.loop
        ? parseInt($slideEl.attr('data-swiper-slide-index'), 10)
        : index;
      const ariaLabelMessage = params.slideLabelMessage
        .replace(/\{\{index\}\}/, slideIndex + 1)
        .replace(/\{\{slidesLength\}\}/, slidesLength);
      addElLabel($slideEl, ariaLabelMessage);
    });

    // Navigation
    let $nextEl;
    let $prevEl;
    if (navigation && navigation.$nextEl) {
      $nextEl = navigation.$nextEl;
    }
    if (navigation && navigation.$prevEl) {
      $prevEl = navigation.$prevEl;
    }

    if ($nextEl && $nextEl.length) {
      initNavEl($nextEl, wrapperId, params.nextSlideMessage);
    }
    if ($prevEl && $prevEl.length) {
      initNavEl($prevEl, wrapperId, params.prevSlideMessage);
    }

    // Pagination
    if (hasClickablePagination()) {
      pagination.$el.on(
        'keydown',
        classesToSelector(params.pagination.bulletClass),
        onEnterOrSpaceKey,
      );
    }

    // Tab focus
    $el.on('focus', handleFocus, true);
  }
  function destroy() {
    if (liveRegion && liveRegion.length > 0) liveRegion.remove();

    let $nextEl;
    let $prevEl;
    if (navigation && navigation.$nextEl) {
      $nextEl = navigation.$nextEl;
    }
    if (navigation && navigation.$prevEl) {
      $prevEl = navigation.$prevEl;
    }
    if ($nextEl) {
      $nextEl.off('keydown', onEnterOrSpaceKey);
    }
    if ($prevEl) {
      $prevEl.off('keydown', onEnterOrSpaceKey);
    }

    // Pagination
    if (hasClickablePagination()) {
      pagination.$el.off(
        'keydown',
        classesToSelector(params.pagination.bulletClass),
        onEnterOrSpaceKey,
      );
    }

    // Tab focus
    $el.off('focus', handleFocus, true);
  }

  on('beforeInit', () => {
    liveRegion = $(
      `<span class="${params.a11y.notificationClass}" aria-live="assertive" aria-atomic="true"></span>`,
    );
  });

  on('afterInit', () => {
    if (!params.a11y.enabled) return;
    init();
  });
  on('fromEdge toEdge afterInit lock unlock', () => {
    if (!params.a11y.enabled) return;
    updateNavigation();
  });
  on('paginationUpdate', () => {
    if (!params.a11y.enabled) return;
    updatePagination();
  });
  on('destroy', () => {
    if (!params.a11y.enabled) return;
    destroy();
  });
}
